<template>
  <div class="Content-Page">
    <div id="realtimeMap" class="wrap-Main">
      <div class="box-S3">
        <l-map :zoom="zoom" :center="center" style="z-index: 0; height: 65vh">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker
            v-for="(item, index) in markers"
            :key="index"
            :lat-lng="item.location"
          >
            <l-icon
              :icon-url="item.iconcheckpoint"
              :icon-size="[32, 32]"
              :icon-anchor="[16, 16]"
            ></l-icon>
            <l-popup ref="popContent">{{ item.name }}</l-popup>
          </l-marker>

          <v-rotated-marker
            class="marker"
            v-for="(item, index) in markersnow"
            :key="index"
            :lat-lng="item.location"
            :icon="icon"
            :rotationAngle="item.rotate"
          >
            <l-popup ref="popContent">{{ item.name }}</l-popup>
          </v-rotated-marker>
          <l-polyline :lat-lngs="latlngs" color="#0BCC01"></l-polyline>
          <l-polyline :lat-lngs="latlngs2" color="#0000FF"></l-polyline>
        </l-map>
      </div>
      <div class="box-S1">
        <div class="B-carddetail">
          <div class="panal-title">Event Log</div>
          <span class="center">
            <v-row>
              <v-col md="12">
                <v-list-item class="T-size-16">
                  <v-list-item-content>Time</v-list-item-content>
                  <v-list-item-content>Event</v-list-item-content>
                  <v-list-item-content>Point</v-list-item-content>
                </v-list-item>

                <v-list
                  class="T-size-14"
                  v-for="item in itemEventLog"
                  :key="item.Id"
                >
                  <div
                    class="T-size-16 SemiBold"
                    v-if="item.t_posntypedescription != 'EnRoute'"
                  >
                    <v-list-item>
                      <v-list-item-content class="T-size-18">{{
                        item.t_posntime | HHmm
                      }}</v-list-item-content>
                      <v-list-item-content class="T-size-18">{{
                        item.t_posntypedescription
                      }}</v-list-item-content>
                      <v-list-item-content class="T-size-18">{{
                        item.t_georef_ident
                      }}</v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list>
                <p v-if="timeOut">TIME OUT</p>
                
              </v-col>
            </v-row>


          </span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { latLng, icon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LIconDefault,
  LPolyline,
  LIcon,
  LPopup,
} from "vue2-leaflet";
import { startOfDay, endOfDay, format, addHours, addMinutes, differenceInMinutes } from "date-fns";
//Add
import { formatDistance } from "date-fns/esm";
import { th } from "date-fns/esm/locale";

import feathersClientUOA from "../plugins/feathers-client-uoa";
import feathersClientSkytrack from "../plugins/feathers-client-skytrack";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import fillerdate from "../utils/filterdate";
import { selectVirtualRow } from "@syncfusion/ej2-vue-grids";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LIcon,
    LPopup,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
  },
  data() {
    return {
      zoom: 8,
      center: L.latLng(7.1874908, 100.6022255),
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      markers: [],
      icon: icon({
        iconUrl: "https://api.prodigyfly.com/uploads/helicopter_new.png",
        iconSize: [60, 60],
        iconAnchor: [30, 30],
      }),
      latlngs: [],
      latlngs2: [],
      dataselect: [],
      selectolicenseplate: "นข4557 สงขลา",
      markersnow: [],
      markpoint: "https://api.prodigyfly.com/uploads/helicopter_new.png",
      tempdatalat: 0,
      tempdatalng: 0,
      itemsflight: null,

      //Add
      itemEventLog: [],
      timeOut : true,
    };
  }, 
  props: ["AircraftName", "StartDate","EndDate", "FlightID", "DepartFrom"],
  async mounted() {
    var ac_name = this.AircraftName;
    var sDate = new Date(this.StartDate +":00");
    var eDate = new Date(this.EndDate + ":00");

    if(this.DepartFrom == "VTSH"){
      this.center = L.latLng(7.1874908, 100.6022255);
    }else{
      this.center = L.latLng(12.677348781116454, 100.99881289556635);
    }

    // var ac_name = "HS-UOL";
    // var sDate = new Date("2020-12-23 07:00:00");
    // var eDate = new Date("2020-12-23 10:00:00");

    //alert(ac_name + "\nsDate : " + sDate + "\neDate : " + eDate)

    await this.renderCheckpoint();
    await this.renderline(this.AircraftName, sDate, eDate);
    await this.renderEventLog(this.AircraftName, sDate, eDate);
  },
  methods: {
    async renderline(unitname, sDate, eDate) {
      //alert("renderline => " + unitname + "\nsDate : " + sDate + "\neDate : " + eDate)
      if (unitname == null) {
        unitname = "";
      }
      try {
        // var x = format(sDate, "yyyy-MM-dd HH:mm:ss");
        // var y = format(eDate, "yyyy-MM-dd HH:mm:ss");
        // const q = {
        //   t_posntime: {
        //     $gt: x,
        //     $lt: y,
        //   },
        //   a_name: unitname,
        //   //Edit ต้องเป็น 1 จากน้อยไปมากเท่านั้น -1 คือจากมากไปน้อยเว้นวนอยู่ที่สงขลา
        //   $sort: {
        //     t_posntime: 1,
        //   },
        // };
        // var res = await feathersClientSkytrack
        //   .service("tracking")
        //   .find({ query: q });

        var q = {
          f_id : this.FlightID,
          $sort: {
            t_posntime: 1,
          },
        };

        var res = await feathersClientSkytrack
          .service("flighttracking")
          .find({ query: q });

        if (res.total > 0) {
          this.timeOut = false;
          var last = res.total - 1;

          this.latlngs = [];
          this.latlngs2 = [];
          res.data.forEach(async (element) => {
            await this.latlngs.push(
              eval("[" + element.t_latitude + "," + element.t_longitude + "]")
            );
          });

          this.markersnow = [];
          var c = {};
          c["location"] = eval(
            "L.latLng(" +
              res.data[0].t_latitude +
              "," +
              res.data[0].t_longitude +
              ")"
          );
          c["name"] = res.data[0].a_name;
          await this.markersnow.push(c);

        }else{
          this.latlngs = [];
          this.latlngs2 = [];
        }
      } catch (err) {
        console.log("ไม่สามารถติดต่อ server ได้" + err);
      }
    },
    async renderCheckpoint() {
      try {
        const q = {};

        var res = await feathersClientSkytrack
          .service("checkpoint")
          .find({ query: q });

        res[0].MapData.forEach((element) => {
          let markcheckpoint = null;
          if (element.type === "Waypoint") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/waypoint.png";
          } else if (element.type === "Fixed Installation") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/oil-rig.png";
          } else if (element.type === "Airport") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/airport.svg";
          } else if (element.type === "Vessel") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/vessel.png";
          } else if (element.type === "Semi-Submersible") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/oil-rig.png";
          } else if (element.type === "Jackup") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/oil-rig.png";
          }

          var c = {};
          c["location"] = eval(
            "L.latLng(" +
              element.position.lat +
              "," +
              element.position.lng +
              ")"
          );
          c["iconcheckpoint"] = markcheckpoint;
          c["name"] = element.name;
    
          this.markers.push(c);
        });
      } catch (err) {
        console.log("ไม่สามารถติดต่อ server ได้" + err);
      }
    },

    async renderEventLog(unitname, sDate, eDate) {
      try {
        var x = format(sDate, "yyyy-MM-dd HH:mm:ss");
        var y = format(eDate, "yyyy-MM-dd HH:mm:ss");

        const quaryEventLog = {
          name: unitname,
          start: x,
          end: y,
        };

        var res = await feathersClientSkytrack
          .service("geteventlog")
          .find({ query: quaryEventLog });
        this.itemEventLog = res;
      } catch (err) {
        console.log("geteventlog ไม่สามารถติดต่อ server ได้" + err);
      }
    },
  },
};
</script>
<style>
.item-click {
  margin-top: 5px;
}

.icon-brown.mdi:before,
.mdi-set {
  color: #ba4a00 !important;
}
.icon-black.mdi:before,
.mdi-set {
  color: #1b2631 !important;
}
.icon-blue.mdi:before,
.mdi-set {
  color: blue !important;
}
</style>